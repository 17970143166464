<template>
  <div>

    <v-toolbar flat dense>

      <record-navigation
        :show="waitingListStore.waitingList.data && waitingListStore.waitingList.data.length > 0"
        :section="5"
        :currentIndex="waitingListStore.currentIndex"
        :lastRecord="waitingListStore.waitingList.total"
        :from="waitingListStore.waitingList.from"
        :to="waitingListStore.waitingList.to"
      />

      <v-spacer />

      <v-toolbar-items>
        <!--Bookmark-->
        <bookmark :key="waitingListStore.waitingList.id" :bookmark-section="4" />

        <!--Add Correspondence-->

        <!--Add Action-->
        <action-entry :action-section="5" />

        <!--Add Note-->
        <note-entry :note-section="5" />

        <!--Add Task-->
        <task-entry :task-section="6" />

      </v-toolbar-items>
    </v-toolbar>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import RecordNavigation from '@/components/RecordNavigation';
import WaitingListEntry from '@/components/WaitingListEntry';
import TaskEntry from '@/components/TaskEntry';
import NoteEntry from '@/components/NoteEntry';
import Bookmark from '@/components/Bookmark';
import ActionEntry from '@/components/ActionEntry';

export default {
  name: 'teacherFunctionBar',
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      waitingListStore: (state) => state.waitingListStore,
    }),
  },
  components: {
    RecordNavigation,
    WaitingListEntry,
    TaskEntry,
    NoteEntry,
    Bookmark,
    ActionEntry,
  },
  methods: {

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
