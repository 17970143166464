<template>
  <div>

    <!-- Edit Waiting List Button -->
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn v-on="on" rounded outlined @click="openEntry()">
          <v-icon small left>fal fa-pencil-alt</v-icon>Edit
        </v-btn>
      </template>
      <span>Edit Waiting List Record</span>
    </v-tooltip>

    <!--Record Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1000">
      <v-card flat class="white" width= "800" height="1030">
        <v-card-title class="text-h5 secondary--text"
          >Waiting List Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-tabs grow show-arrows="mobile" slider-color="secondary" v-model="tab">
                <v-tab>Details</v-tab>
                <v-tab>Household and Employment</v-tab>
                <v-tab>Children</v-tab>
                <v-tab>UNC-Chapel Hill Affiliation</v-tab>
                <v-tab>Options and Notes</v-tab>

                <!--Details-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <!--WLS ID-->
                          <v-col cols="12" sm="3">
                            <v-text-field
                              v-model="d_wlsID"
                              label="WLS ID"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                          <!--Wait List Pull ID-->
                          <v-col cols="12" sm="3">
                            <v-text-field
                              v-model="d_waitListPullID"
                              label="Wait List Pull ID"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                          <!--Type-->
                          <v-col cols="12" sm="12" md="3" offset-md="3">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[44]"
                              label="Type"
                              item-text="d_name"
                              item-value="d_name"
                              v-model="d_type"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Specialist-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="usersStore.usersValueList"
                              label="Specialist"
                              placeholder=" "
                              persistent-placeholder
                              item-text="name"
                              item-value="id"
                              v-model="fk_counselorID"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                          <!--Initial Specialist-->
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="d_nameInitialCounselor"
                              label="Initial Specialist"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>

                          <!--Case Type-->
                          <v-col cols="12" sm="12" md="3" offset-md="1">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[46]"
                              label="Case Type"
                              item-text="d_name"
                              item-value="d_name"
                              v-model="d_caseType"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Priority-->
                          <v-col cols="12" sm="12" md="8" class="px-0">
                            <span class="grey--text text--darken-1 text-caption">Priority</span>
                            <v-radio-group row dense class="my-0 py-0">
                              <v-checkbox
                                dense
                                :key="index"
                                v-for="(item, index) in valueListsStore.valueListItems[39]"
                                v-model="j_priority"
                                :label="item.d_name"
                                :value="item.d_name"
                                color="primary"
                                multiple
                                class="pr-2 py-0 my-0"
                              ></v-checkbox>
                            </v-radio-group>
                          </v-col>
                          <!--Income Range-->
                          <v-col cols="12" sm="12" md="2" offset-md="1">
                            <v-text-field
                              v-model="f_incomeRank"
                              label="Income Range"
                              type="number"
                              min="0"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Date WLS Update From Screening-->
                          <v-col cols="12" sm="12" md="4">
                            <v-menu
                              :close-on-content-click="false"
                              v-model="menu2"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  v-model="d_dateWLSupdateFromScreening"
                                  label="Date Work Life Update From Screening"
                                  placeholder=" "
                                  persistent-placeholder
                                  prepend-icon="fal fa-calendar-alt"
                                  required
                                  @blur="date2 = $_parseDate(d_dateWLSupdateFromScreening)"
                                />
                              </template>
                              <v-date-picker
                                v-model="date2"
                                @input="menu2 = false"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <!--Last Screening Date-->
                          <v-col cols="12" sm="12" md="4">
                            <v-menu
                              :close-on-content-click="false"
                              v-model="menu"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  v-model="d_dateLastScreening"
                                  label="Last Screening Date"
                                  placeholder=" "
                                  persistent-placeholder
                                  prepend-icon="fal fa-calendar-alt"
                                  required
                                  @blur="date1 = $_parseDate(d_dateLastScreening)"
                                />
                              </template>
                              <v-date-picker
                                v-model="date1"
                                @input="menu = false"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <!--Last Screening Status-->
                          <v-col cols="12" sm="12" md="4">
                            <v-text-field
                              v-model="d_statusLastScreening"
                              label="Last Screening Status"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Household and Employment-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>

                        <v-row>
                          <!--Parent First Name-->
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="d_nameFirst"
                              label="Parent First Name"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                          <!--Parent Last Name-->
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="d_nameLast"
                              label="Parent Last Name"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                          <!--Client Age-->
                          <v-col cols="12" sm="12" md="4">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[40]"
                              label="Applicant Age"
                              item-text="d_name"
                              item-value="d_name"
                              v-model="d_age"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                          <!--Applicant Birth Date-->
                          <v-col cols="12" sm="12" md="2">
                            <v-menu
                              :close-on-content-click="false"
                              v-model="menu3"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                            >
                              <template #activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  v-model="d_dateBirth"
                                  label="Birth Date"
                                  placeholder=" "
                                  persistent-placeholder
                                  prepend-icon="fal fa-calendar-alt"
                                  required
                                  @blur="date3 = $_parseDate(d_dateBirth)"
                                />
                              </template>
                              <v-date-picker
                                v-model="date3"
                                @input="menu3 = false"
                                color="primary"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>

                        <v-row>
                          <!--Primary Language-->
                          <v-col cols="12" sm="12" md="3">
                            <v-autocomplete
                              :items="valueListsStore.valueListItems[15]"
                              label="Primary Language"
                              placeholder=" "
                              persistent-placeholder
                              item-text="d_name"
                              item-value="d_name"
                              v-model="d_languagePrimary"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                          <!--Gender-->
                          <v-col cols="12" sm="12" md="2">
                            <v-autocomplete
                              :items="this.genderWIS"
                              label="Gender"
                              placeholder=" "
                              persistent-placeholder
                              item-text="gender"
                              item-value="gender"
                              v-model="d_gender"
                              clearable
                              clear-icon="fal fa-times-circle"
                            />
                          </v-col>
                          <!--Relation to Child-->
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="d_relationshipToChild"
                              label="Relationship to Child"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-divider />
                        <v-divider />

                        <v-row dense>
                          <!--Street Address-->
                          <v-col cols="12" sm="12" md="5" class="py-5">
                            <v-text-field
                              v-model="d_residenceStreet"
                              label="Residence Street Address"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                          <!--City-->
                          <v-col cols="12" sm="12" md="5" class="py-5">
                            <v-text-field
                              v-model="d_residenceCity"
                              label="Residence City"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                          <!--State-->
                          <v-col cols="12" sm="12" md="2" class="py-5">
                            <v-text-field
                              v-model="d_residenceState"
                              label="Residence State"
                              placeholder=" "
                              persistent-placeholder
                              required
                              @blur="d_state = $_formatStateInitials(d_state)"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--County-->
                          <v-col cols="12" sm="12" md="5">
                            <v-text-field
                              v-model="d_residenceCounty"
                              label="Residence County"
                              placeholder=" "
                              persistent-placeholder
                            >
                            </v-text-field>
                          </v-col>
                          <!--Zipcode-->
                          <v-col cols="12" md="2">
                            <v-text-field
                              v-model="d_residencePostalCode"
                              label="Residence Zipcode"
                              placeholder=" "
                              persistent-placeholder
                              required
                              :rules="rules.only_digits"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>

                        <!--Primary Phone-->
                        <v-row dense>
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="d_phonePrimary"
                              label="Primary Phone"
                              placeholder=" "
                              persistent-placeholder
                              :blur="d_phonePrimary = $_formatPhone(d_phonePrimary)"
                            />
                          </v-col>

                          <!--Secondary Phone-->
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="d_phoneSecondary"
                              label="Secondary Phone"
                              placeholder=" "
                              persistent-placeholder
                              :blur="d_phoneSecondary = $_formatPhone(d_phoneSecondary)"
                            />
                          </v-col>
                          <!--Email-->
                          <v-col cols="12" sm="12" md="6">
                            <v-text-field
                              v-model="d_emailLogin"
                              label="Login Email"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>

                        <v-divider />
                        <v-divider class="pt-2"/>

                        <v-row dense class="mb-1">
                          <v-col cols="12" sm="12" md="6" class="py-5 px-5 pb-2">
                            <v-row dense>
                              <!--Adults in Household-->
                              <v-col cols="12" sm="12" md="8">
                                <v-autocomplete
                                  :items="valueListsStore.valueListItems[41]"
                                  label="Adults in Household"
                                  item-text="d_name"
                                  item-value="d_name"
                                  v-model="d_adultsInHousehold"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>

                              <!--Family Size-->
                              <v-col cols="12" sm="12" md="4">
                                <v-text-field
                                  v-model="d_familySize"
                                  label="Family Size"
                                  type="number"
                                  min="0"
                                  placeholder=" "
                                  persistent-placeholder
                                />
                              </v-col>
                            </v-row>

                            <v-row dense>
                              <!--Income-->
                              <v-col cols="12" sm="12" md="12">
                                <v-autocomplete
                                  :items="valueListsStore.valueListItems[42]"
                                  label="Income"
                                  item-text="d_name"
                                  item-value="d_name"
                                  v-model="d_income"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <!--Family Income-->
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_incomeFamily"
                                  label="Family Income"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_dollarDigits"
                                />
                              </v-col>

                              <!--Adjusted Income-->
                              <v-col cols="12" sm="12" md="6">
                                <v-text-field
                                  v-model="d_incomeAdjusted"
                                  label="Adjusted Income"
                                  placeholder=" "
                                  persistent-placeholder
                                  :rules="rules.only_dollarDigits"
                                />
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-divider vertical />
                          <v-divider vertical />

                          <v-col cols="12" sm="12" md="6" class="py-5 px-5 pb-2">
                            <v-row dense>
                              <!--Employment Status-->
                              <v-col cols="12" sm="12" md="12">
                                <v-autocomplete
                                  :items="valueListsStore.valueListItems[43]"
                                  label="Employment Status"
                                  item-text="d_name"
                                  item-value="d_name"
                                  v-model="d_statusEmployment"
                                  clearable
                                  clear-icon="fal fa-times-circle"
                                />
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <!--Employer-->
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_employer"
                                  label="Employer"
                                  placeholder=" "
                                  persistent-placeholder
                                />
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <!--Employer Other-->
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="d_employerOther"
                                  label="Other Employer"
                                  placeholder=" "
                                  persistent-placeholder
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-divider />
                        <v-divider />

                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Children Information-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <v-spacer />
                          <v-btn
                            rounded
                            dark
                            color="green lighten-1"
                            class="mb-2"
                            @click="addChild()"
                          >
                            <v-icon small left>fal fa-plus</v-icon>New</v-btn
                          >
                          <v-col cols="12" sm="12">
                            <v-card flat :key="index" v-for="(item, index) in j_children">
                              <v-container fluid class="pa-0">
                                <v-row dense>
                                  <v-col cols="12" sm="12" md="4">
                                    <v-text-field
                                      v-model="item.d_name"
                                      label="Name"
                                      placeholder=" "
                                      persistent-placeholder
                                      flat
                                      hide-details
                                    />
                                  </v-col>

                                  <!-- Child Date Birth -->
                                  <v-col cols="12" sm="12" md="3">
                                    <v-text-field
                                      v-model="item.date4"
                                      label="Birth Date"
                                      placeholder=" "
                                      persistent-placeholder
                                      prepend-icon="fal fa-calendar-alt"
                                      required
                                      @blur="item.date4 = $_formatStringDate(item.date4);
                                      item.d_dateBirth = $_parseDate(item.date4)"
                                      hint="MM/DD/YYYY"
                                    />
                                  </v-col>

                                  <!--Gender-->
                                  <v-col cols="12" sm="6" md="3">
                                    <v-autocomplete
                                      :items="valueListsStore.valueListItems[48]"
                                      label="Gender"
                                      placeholder=" "
                                      persistent-placeholder
                                      item-text="d_name"
                                      item-value="d_name"
                                      v-model="item.d_gender"
                                      clearable
                                      clear-icon="fal fa-times-circle"
                                    />
                                  </v-col>
                                  <v-col
                                    v-if="usersStore.authUser.f_delete === 1"
                                    cols="12"
                                    sm="1"
                                    offset-sm="1"
                                  >
                                    <v-btn
                                      text
                                      icon
                                      color="red"
                                      @click="removeChild(index)"
                                    >
                                      <v-icon>fal fa-trash</v-icon>
                                    </v-btn>
                                  </v-col>
                                </v-row>

                                <v-row dense>
                                  <!--IEP and IFSP-->
                                  <v-col cols="12" sm="2">
                                    <v-autocomplete
                                      :items="valueListsStore.yesNo"
                                      label="IEP/IFSP"
                                      placeholder=" "
                                      persistent-placeholder
                                      item-text="value"
                                      item-value="id"
                                      v-model="item.f_IEPIFSP"
                                    />
                                  </v-col>

                                  <!--Special Needs-->
                                  <v-col cols="12" sm="2">
                                    <v-autocomplete
                                      :items="valueListsStore.yesNo"
                                      label="Special Needs"
                                      placeholder=" "
                                      persistent-placeholder
                                      item-text="value"
                                      item-value="id"
                                      v-model="item.f_specialNeeds"
                                    />
                                  </v-col>

                                  <!--Subsidy-->
                                  <v-col cols="12" sm="3">
                                    <v-autocomplete
                                      :items="valueListsStore.valueListItems[47]"
                                      label="Subsidy"
                                      placeholder=" "
                                      persistent-placeholder
                                      item-text="d_name"
                                      item-value="d_name"
                                      v-model="item.d_subsidy"
                                    />
                                  </v-col>
                                </v-row>

                                <v-divider />
                                <v-divider />
                              </v-container>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--UNC-CH Affilation-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-container fluid>
                        <v-row dense>
                          <!--UNC-Chapel Hill Affiliate-->
                          <v-col cols="12" sm="5" md="4">
                            <v-text-field
                              v-model="d_affiliateUNCCH"
                              label="UNC-CH Affiliate"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>

                          <!--Other UNC-Chapel Hill Affiliate-->
                          <v-col cols="12" sm="5" md="4">
                            <v-text-field
                              v-model="d_affiliateUNCCHother"
                              label="Other UNC-CH Affiliate"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <!--Caller First Name-->
                          <v-col cols="12" sm="5" md="4">
                            <v-text-field
                              v-model="d_nameFirstCaller"
                              label="Caller First Name"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>

                          <!--Caller Last Name-->
                          <v-col cols="12" sm="5" md="4">
                            <v-text-field
                              v-model="d_nameLastCaller"
                              label="Caller Last Name"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>

                          <!--Caller Email-->
                          <v-col cols="12" sm="5" md="4">
                            <v-text-field
                              v-model="d_emailCaller"
                              label="Caller Email"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!--Other Information and Notes-->
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="px-0">
                      <v-container fluid>
                        <v-row dense>
                          <!--Enrolled/ Needs Assistance-->
                          <v-col class="pt-0 pb-4" cols="12" sm="12" md="4">
                            <v-checkbox
                              v-model="f_enrolledNeedsAssistance"
                              label="Enrolled/ Needs Assistance"
                              color="primary"
                              class="pr-2"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                          <!--Gave CCSA WL Consent-->
                          <v-col class="pt-0 pb-4" cols="12" sm="12" md="4">
                            <v-checkbox
                              v-model="f_ccsaWLConsent"
                              label="Gave CCSA WL Consent"
                              color="primary"
                              class="pr-2"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                          <!--Subsidy-Homeless WL-->
                          <v-col class="pt-0 pb-4" cols="12" sm="12" md="4">
                            <v-checkbox
                              v-model="f_subsidyHomelessWL"
                              label="Subsidy-Homeless WL"
                              color="primary"
                              class="pr-2"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--CCSA Scholarship WL-->
                          <v-col class="pt-0 pb-4" cols="12" sm="12" md="4">
                            <v-checkbox
                              v-model="f_ccsaScholarshipWL"
                              label="CCSA Scholarship WL"
                              color="primary"
                              class="pr-2"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                          <!--CCSA Scholarship Receiving-->
                          <v-col class="pt-0 pb-4" cols="12" sm="12" md="4">
                            <v-checkbox
                              v-model="f_ccsaScholarshipReceiving"
                              label="CCSA Scholarship Receiving"
                              color="primary"
                              class="pr-2"
                              hide-details
                            >
                            </v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <!--Notes-->
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="d_note"
                              label="Notes"
                              placeholder=" "
                              persistent-placeholder
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>

              <v-row dense>
              <!--Status-->
                <v-col cols="12" sm="12" md="5" offset-md="7">
                  <v-autocomplete
                    :items="valueListsStore.valueListItems[45]"
                    label="Status"
                    item-text="d_name"
                    item-value="d_value"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";

const { mapFields } = createHelpers({
  getterType: "waitingListStore/getEntryField",
  mutationType: "waitingListStore/updateEntryField"
});

export default {
  name: "WaitingListEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      waitingListStore: state => state.waitingListStore,
      valueListsStore: state => state.valueListsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "show",
      "valid",
      "section",
      "id",
      "fk_counselorID",
      "d_wlsID",
      "d_waitListPullID",
      "j_priority",
      "f_incomeRank",
      "d_dateLastScreening",
      "d_statusLastScreening",
      "date1",
      "d_dateWLSupdateFromScreening",
      "date2",
      "d_type",
      "d_nameInitialCounselor",
      "d_caseType",
      "d_nameFirst",
      "d_nameLast",
      "d_dateBirth",
      "date3",
      "d_gender",
      "d_age",
      "d_languagePrimary",
      "d_relationshipToChild",
      "d_residenceStreet",
      "d_residenceCity",
      "d_residenceCounty",
      "d_residenceState",
      "d_residencePostalCode",
      "d_emailLogin",
      "d_phonePrimary",
      "d_phoneSecondary",
      "d_adultsInHousehold",
      "d_familySize",
      "d_income",
      "d_incomeAdjusted",
      "d_incomeFamily",
      "d_statusEmployment",
      "d_employer",
      "d_employerOther",
      "d_affiliateUNCCH",
      "d_affiliateUNCCHother",
      "d_nameFirstCaller",
      "d_nameLastCaller",
      "d_emailCaller",
      "d_note",
      "f_ccsaScholarshipWL",
      "f_subsidyHomelessWL",
      "f_enrolledNeedsAssistance",
      "f_ccsaScholarshipReceiving",
      "f_ccsaWLConsent",
      "f_status"

    ]),
    ...mapMultiRowFields("waitingListStore", ["entry.j_children"])
  },
  props: {
    /* All List = 0 */
    contactSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      tab: 0,
      genderWIS: [
        {gender: 'Male'},
        {gender: 'Female'}
      ],
      rules: {
        only_digits: [
          //apply validation if the field is populated !v
          v => !v || /^\d+$/.test(v) || "Enter Digits Only"
        ],
        only_dollarDigits: [
          v => !v || /^\d+(?:\.\d{1,2})?/.test(v) && /^[^,]+$/.test(v) || "Enter Dollar Amount - Digits and Decimal Only"
        ]
      },
    };
  },
  watch: {
    date1() {
      this.d_dateLastScreening = this.$_formatDate(this.date1);
    },
    date2() {
      this.d_dateWLSupdateFromScreening = this.$_formatDate(this.date2);
    },
    date3() {
      this.d_dateBirth = this.$_formatDate(this.date3);
    }
  },
  methods: {
    resetEntry() {
      this.$store.dispatch("waitingListStore/resetEntry");
    },

    async openEntry() {
      this.resetEntry();

      /* Pull valueListsStore items for Entry forms */
      const data2 = {
        id: [15, 32, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48]
      };
      await this.$store.dispatch("valueListsStore/items", data2);

      const waitingListItem = this.$store.getters["waitingListStore/getWaitingListItem"];
      await this.$store.dispatch("waitingListStore/entry", waitingListItem.uuid);

      this.tab = 0;
      this.show = true;
    },

    cancelEntry() {
      this.tab = 0;
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["waitingListStore/getEntry"];
        /* Edit Record */
        //Remove date4 property from j_children before updating table
        entry.j_children.forEach(function(child) {
          delete child['date4'];
        });

        await this.$store.dispatch("waitingListStore/update", entry);

        this.cancelEntry();
      }
    },

    addChild() {
      this.$store.dispatch("waitingListStore/addChild");
    },

    removeChild(id) {
      this.$store.dispatch("waitingListStore/removeChild", id);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
